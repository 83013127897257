<template>
  <div class="wrapper">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="" name="1">
              <template slot="title">
                <div class="subTit">
                  <el-icon class="el-icon-files"> </el-icon>快捷菜单
                </div>
              </template>
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix"
        :class="collapsed ? 'screen' : ''"
      >
        <el-form class="normalFrom" @submit.native.prevent>
          <div class="clearfix" style="padding: 0 15px">
            <el-row :span="24">
              <el-col
                class="searchRow"
                style="display: flex; align-items: self-start"
              >
                <div class="fl" style="width: 100%">
                  <el-form-item label style="margin-bottom: 10px">
                    <el-input
                      class="search"
                      v-model="searchInfo.searchKey"
                      @keyup.enter.native="suBgetData()"
                      placeholder="请输入项目名称 / 姓名，支持模糊搜索"
                    >
                      <el-button
                        type="primary"
                        slot="append"
                        @click="suBgetData()"
                        >搜 索</el-button
                      >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <div style="padding-bottom: 10px">
          <el-row :gutter="24">
            <el-col
              :span="24"
              class="table-top"
              style="margin-top: 0; margin-bottom: 0"
            >
              <el-pagination
                :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
                v-if="searchInfo.page.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchInfo.page.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="searchInfo.page.size"
                layout="total,prev, pager, next,sizes"
                :total="searchInfo.page.total"
              ></el-pagination>
            </el-col>
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                }"
                :data="ListData"
                class="tabBorder custor"
                v-loading="listLoading"
              >
                <el-table-column label="姓名" width="120">
                  <template #default="scope">
                    <span
                      class="tabHref"
                      @click="showDrawer(scope.row.resumeId)"
                      >{{ scope.row.resumeName }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="项目" show-overflow-tooltip>
                  <template #default="scope">
                    <router-link
                      class="tabHref"
                      target="_blank"
                      :to="{
                        path: '/project/details',
                        query: { id: scope.row.projectId },
                      }"
                      >{{ scope.row.projectName }}
                    </router-link>
                  </template></el-table-column
                >
                <el-table-column
                  prop="approvalType"
                  label="审批类型"
                ></el-table-column>
                <el-table-column prop="approvalStatus" label="审批状态">
                  <template slot-scope="scope">
                    <span>{{ scope.row.approvalStatus }}</span>
                    <el-tooltip
                      v-if="scope.row.approvalStatus == '自动失败'"
                      content="系统自动处理为‘失败’，如有疑问请与管理员咨询"
                      placement="top"
                      effect="dark"
                    >
                      <i
                        class="iconfont icon icon-wenhao-xiangsu"
                        style="font-size: 14px; margin: 10px"
                      ></i>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdTime"
                  label="创建时间"
                ></el-table-column>
                <el-table-column label="操作" width="180">
                  <template slot-scope="scope"
                    ><span class="tabHref" @click="showDetail(scope.row)"
                      >查看详情</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
                v-if="searchInfo.page.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchInfo.page.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="searchInfo.page.size"
                layout="total,prev, pager, next,sizes"
                :total="searchInfo.page.total"
              ></el-pagination>
            </el-col>
          </el-row>
        </div>
      </section>
    </el-row>
    <!-- 人选详情 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="approvalDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ apprtit }}</div>
      <div class="drawerCon">
        <noComtractApporval
          ref="noComtractApporval"
          v-if="apprtit == '开票申请详情'"
          :id="contractId"
          :onRead="true"
          @close="handleClose"
        ></noComtractApporval>
        <happyNewsDetail
          ref="happyNewsDetail"
          v-if="apprtit == '喜报详情'"
          :id="contractId"
          @close="handleClose"
        ></happyNewsDetail>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { personalApprovalPage } from "../../api/api";
import { mapGetters } from "vuex";
import resumeDetails from "../../components/resumeDetail";
import noComtractApporval from "../../components/tools/noComtractApporval";
import happyNewsDetail from "../../components/tools/happyNewsDetail";
export default {
  components: { resumeDetails, noComtractApporval, happyNewsDetail },
  data() {
    return {
      activeNames: ["1", "2"],
      menuList: [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ],
      actived: "我提交的审批",
      OrderSearchList: [],
      searchInfo: {
        page: {
          current: 1,
          size: 50,
          pages: null,
          total: 0,
        },
        searchKey: "",
      },
      ListData: [],
      listLoading: false,
      drawerVisible: false,
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      dialogVisibleBack: false,
      enrtyForm: {
        result: null,
        time: null,
        endTimeOfTrial: null,
        addToSchedule: true,
        id: 0,
        version: 0,
        projectFinished: false,
      },
      CommentWay: "",
      freeBackId: "",
      disabled: false,
      dialogVisibleBackzz: false,
      transferForm: {
        addToSchedule: false,
        endTimeOfTrial: null,
        entryStatus: null,
        projectResumeId: null,
      },
      CommentWay1: "",
      defaultTime: null,
      pickerOptions: {
        disabledDate(time) {
          return Date.now() > time.getTime();
        },
      },
      pickerOptions1: {},
      approvalDrawerVisible: false,
      contractId: "",
      apprtit: "",
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    if (this.$route.query.status) {
      this.actived = this.$route.query.status;
      this.searchInfo.quickType = this.$route.query.status;
    }
    let permTypes = this.getStore("permiss");
    if (permTypes.includes("Pipeline:teamPipeline")) {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ];
    } else {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
      ];
    }
    this.getData();
  },
  methods: {
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortCheck = null;
        this.sortType = "";
        this.currentItem = "";
        this.listLoading = true;
        if (val == "我offer的候选人") {
          this.$router.push({
            path: "/Pipeline/offerResume",
          });
          return;
        } else if (val == "我提交的审批") {
          this.$router.push({
            path: "/Pipeline/approvalSubmit",
          });
          return;
        } else if (val == "团队Pipeline") {
          this.$router.push({
            path: "/Pipeline/teamPipeline",
          });
          return;
        }
        switch (type) {
          case "quick": //快捷菜单
            this.$router.push({
              path: "/Pipeline",
              query: {
                status: val,
              },
            });
            break;
        }
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.searchInfo.page.size = val;
      this.searchInfo.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.searchInfo.page.current = val;
      this.getData();
    },
    suBgetData() {
      this.searchInfo.page.current = 1;
      this.getData();
    },
    //获取表格
    getData() {
      this.listLoading = true;
      personalApprovalPage(this.searchInfo).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.searchInfo.page.total = res.result.total;
        }
      });
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
      this.approvalDrawerVisible = false;
    },
    //反馈入职结果
    showDetail(data) {
      if (data.approvalType == "无合同开票审批") {
        this.apprtit = "开票申请详情";
        this.approvalDrawerVisible = true;
        this.contractId = data.approvalId;
      } else if (data.approvalType == "喜报审批") {
        this.apprtit = "喜报详情";
        this.approvalDrawerVisible = true;
        this.contractId = data.approvalId;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../collapsed.less";
@import "./index.less";
.searchL {
  width: calc(100% - 190px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  // float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    // border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tag {
  margin-right: 8px;
}
</style>
