<template>
  <section v-loading="listLoading">
    <el-row :gutter="20" :span="24" class="happyNewsDetail">
      <el-col :span="17" style="padding-bottom: 55px">
        <el-form>
          <el-collapse accordion v-model="activeNames">
            <el-collapse-item name="score">
              <template slot="title">
                <div class="scoreTotal">喜报信息</div>
              </template>
              <p class="tips">
                <i class="el-icon-warning"></i
                >提示：已勾选的内容将显示在公示的喜报中
              </p>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="候选人姓名：">
                    {{ happyNewsForm.candidateNameItem.name }}
                    <el-checkbox
                      v-model="happyNewsForm.candidateNameItem.isShow"
                      style="margin: 0 10px"
                      disabled
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="companyNameItem.name" label="公司简称：">
                    <span
                      v-if="
                        happyNewsForm.companyNameItem.name &&
                        happyNewsForm.companyNameItem.name.length > 10
                      "
                      :title="happyNewsForm.companyNameItem.name"
                      >{{
                        happyNewsForm.companyNameItem.name.substring(0, 10)
                      }}…</span
                    >
                    <span v-else>{{ happyNewsForm.companyNameItem.name }}</span>
                    <el-checkbox
                      disabled
                      v-model="happyNewsForm.companyNameItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="positionItem.name" label="职位名称：">
                    <span>{{
                      happyNewsForm.positionItem.name
                        ? happyNewsForm.positionItem.name
                        : "-"
                    }}</span>
                    <el-checkbox
                      disabled
                      v-model="happyNewsForm.positionItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    class="offerStyle"
                    prop="offerTimeItem.name"
                    label="offer时间："
                  >
                    <span>{{
                      happyNewsForm.offerTimeItem.name
                        ? happyNewsForm.offerTimeItem.name
                        : "-"
                    }}</span>
                    <el-checkbox
                      disabled
                      label=""
                      v-model="happyNewsForm.offerTimeItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="offer金额：" prop="offerMoneyItem.name">
                    <span>{{
                      happyNewsForm.offerMoneyItem.name
                        ? happyNewsForm.offerMoneyItem.name
                        : "-"
                    }}</span>
                    <el-checkbox
                      disabled
                      v-model="happyNewsForm.offerMoneyItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="feeStandard" label="收费标准：">
                    <span>{{
                      happyNewsForm.feeStandard
                        ? happyNewsForm.feeStandard
                        : "-"
                    }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="entryTimeItem.name" label="预到岗时间：">
                    <span>{{
                      happyNewsForm.entryTimeItem.name
                        ? happyNewsForm.entryTimeItem.name
                        : "-"
                    }}</span>
                    <el-checkbox
                      disabled
                      v-model="happyNewsForm.entryTimeItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    prop="moneyItem.name"
                    label="服务费用："
                    style="margin-bottom: 0"
                  >
                    <span>{{
                      happyNewsForm.moneyItem.name
                        ? happyNewsForm.moneyItem.name
                        : "-"
                    }}</span>
                    <el-checkbox
                      disabled
                      v-model="happyNewsForm.moneyItem.isShow"
                      style="margin: 0 10px"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item
                    prop="performanceIndustryIds"
                    label="业绩归属行业："
                    style="margin-bottom: 0"
                  >
                    <span
                      v-for="(
                        item, index
                      ) in happyNewsForm.performanceIndustries"
                      :key="index"
                    >
                      {{ index > 0 ? "、" : "" }}{{ item ? item : "-" }}</span
                    >
                    <el-tooltip
                      content="将作为【行业业绩】数据统计依据"
                      placement="top"
                      effect="dark"
                    >
                      <i class="iconfont icon-wenhao-xiangsu"></i>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item style="margin-bottom: 0">
                    <el-checkbox disabled v-model="happyNewsForm.addToSchedule"
                      >将预到岗时间添加到日程提醒</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <div class="searchList">
            <el-collapse accordion v-model="activeNames1" class="scoreTitle">
              <el-collapse-item name="score">
                <template slot="title">
                  <div class="scoreTotal">
                    业绩分配<span>（当前分配比例总和：100%）</span>
                  </div>
                </template>
                <div style="padding: 10px">
                  <el-collapse accordion>
                    <el-collapse-item
                      class="titleOfPerformance"
                      v-for="(
                        item, index
                      ) in happyNewsForm.performanceAllocations"
                      :key="index"
                    >
                      <template slot="title">
                        <div class="liStyle">
                          <span>
                            <el-select
                              placeholder="请选择"
                              v-model="item.id"
                              disabled
                              size="mini"
                            >
                              <el-option
                                v-for="(
                                  i, indexKPI
                                ) in happyNewsForm.defaultAllocations"
                                :key="'sel' + indexKPI"
                                :label="i.roleName"
                                :value="i.id"
                                :disabled="i.rdisabled"
                              ></el-option>
                            </el-select>
                          </span>
                          <span>
                            <el-select
                              v-model="
                                happyNewsForm.performanceAllocations[index]
                                  .userId
                              "
                              placeholder="请选择"
                              collapse-tags
                              filterable
                              style="width: 200px; margin-left: 10px"
                              size="mini"
                              clearable
                              disabled
                            >
                              <el-option
                                v-for="(i, index) in userList"
                                :key="'selUser' + index"
                                :label="i.name"
                                :value="i.id"
                              ></el-option>
                            </el-select>
                          </span>
                          <el-form-item
                            label="比例："
                            style="display: inline-block"
                            label-width="80px"
                            class="is-required"
                          >
                            <div @click.stop style="display: inline-block">
                              <el-input-number
                                disabled
                                v-model="item.percent"
                                :max="100"
                                :min="0"
                                label="比例"
                              ></el-input-number>
                            </div>
                          </el-form-item>
                        </div>
                      </template>
                      <div class="contentOfPerformance">
                        <el-form>
                          <el-row>
                            <el-col :span="11" class="canEdit">
                              <el-form-item label="集团：">
                                <span
                                  v-for="(_item, idx) in item.allocationItems
                                    ? item.allocationItems.groups
                                    : []"
                                  :key="idx"
                                  >{{ _item.departmentName }} -
                                  {{ _item.percent }}%<span
                                    v-if="
                                      item.allocationItems.groups &&
                                      idx !=
                                        item.allocationItems.groups.length - 1
                                    "
                                    >、</span
                                  ></span
                                >
                              </el-form-item>
                            </el-col>
                            <el-col :offset="1" :span="11" class="canEdit">
                              <el-form-item label="地区：">
                                <span
                                  v-for="(_item, idx) in item.allocationItems
                                    ? item.allocationItems.areas
                                    : []"
                                  :key="idx"
                                  >{{ _item.departmentName }} -
                                  {{ _item.percent }}%<span
                                    v-if="
                                      item.allocationItems.areas &&
                                      idx !=
                                        item.allocationItems.areas.length - 1
                                    "
                                    >、</span
                                  ></span
                                >
                              </el-form-item>
                            </el-col>
                            <el-col :span="11" class="canEdit">
                              <el-form-item label="团队：">
                                <span
                                  v-for="(_item, idx) in item.allocationItems
                                    ? item.allocationItems.teams
                                    : []"
                                  :key="idx"
                                  >{{ _item.departmentName }} -
                                  {{ _item.percent }}%<span
                                    v-if="
                                      item.allocationItems.teams &&
                                      idx !=
                                        item.allocationItems.teams.length - 1
                                    "
                                    >、</span
                                  ></span
                                >
                              </el-form-item>
                            </el-col>
                            <el-col :offset="1" :span="11" class="canEdit">
                              <el-form-item label="子团队：">
                                <span
                                  v-for="(_item, idx) in item.allocationItems
                                    ? item.allocationItems.subTeams
                                    : []"
                                  :key="idx"
                                  >{{ _item.departmentName }} -
                                  {{ _item.percent }}%<span
                                    v-if="
                                      item.allocationItems.subTeams &&
                                      idx !=
                                        item.allocationItems.subTeams.length - 1
                                    "
                                    >、</span
                                  ></span
                                >
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-form>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="searchList" v-if="happyNewsForm.performanceOthers">
            <el-collapse accordion v-model="activeNames2" class="scoreTitle">
              <el-collapse-item name="score">
                <template slot="title">
                  <div class="scoreTotal">
                    业绩备注<span
                      >（实习生/Researcher的业绩详情请填写在这里）</span
                    >
                  </div>
                </template>
                <div style="padding: 4px 10px">
                  <div
                    v-for="(item1, index1) in happyNewsForm.performanceOthers"
                    :key="index1"
                  >
                    <div class="liStyle">
                      <span>
                        实习生/Researcher：
                        <el-select
                          placeholder="请选择类型"
                          v-model="item1.type"
                          clearable
                          size="mini"
                          disabled
                        >
                          <el-option
                            v-for="(i, indexKPI) in srType"
                            :key="'sel' + indexKPI"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <span>
                        <el-select
                          v-model="
                            happyNewsForm.performanceOthers[index1].userId
                          "
                          placeholder="请选择"
                          collapse-tags
                          filterable
                          style="width: 200px; margin-left: 10px"
                          size="mini"
                          clearable
                          disabled
                        >
                          <el-option
                            v-for="(i, index) in userList"
                            :key="'selUser' + index"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <el-form-item
                        label="比例："
                        style="display: inline-block"
                        label-width="80px"
                        :class="item1.type == 'Researcher' ? 'is-required' : ''"
                      >
                        <div @click.stop style="display: inline-block">
                          <el-input-number
                            v-model="item1.percent"
                            :max="100"
                            :min="0"
                            label="比例"
                            disabled
                          ></el-input-number>
                        </div>
                      </el-form-item>
                      <div style="margin: 10px 0 0">
                        团队：
                        <el-select
                          placeholder="请选择团队"
                          v-model="item1.teamId"
                          clearable
                          size="mini"
                          disabled
                        >
                          <el-option
                            v-for="(i, index) in teamsList"
                            :key="'team' + index"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-form>
      </el-col>
      <el-col :span="7" style="padding-left: 0">
        <div class="canRecordBox">
          <div class="canRecord">审批流程</div>
          <el-timeline class="canRecordcon">
            <el-timeline-item
              v-for="(activity, index) in happyNewsForm.approvalProcesses"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
            >
              <div class="approvalTime">{{ activity.time }}</div>
              <div style="color: #606266" v-html="activity.action">
                {{ activity.action }}
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import {
  offerGoodnewsApprovalDetail,
  nameAndStatusOptions,
  getTeamsByType, // 获取团队
} from "../../api/api";
export default {
  props: ["id"],
  data() {
    return {
      activeNames: ["score"],
      activeNames1: ["score"],
      activeNames2: ["score"],
      listLoading: false,
      happyNewsForm: {},
      totalPercent: 0,
      userList: [],
      teamsList: [],
    };
  },
  created() {
    this.getDetail();
    this.loadUserBase();
    this.getTeams();
  },
  methods: {
    getDetail() {
      this.listLoading = true;
      offerGoodnewsApprovalDetail({ goodNewsId: this.id }).then((res) => {
        if (res.success) {
          this.happyNewsForm = res.result;
        }
        this.listLoading = false;
      });
    },
    loadUserBase() {
      //获取用户列表
      nameAndStatusOptions().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    // 获取团队
    getTeams() {
      getTeamsByType().then((res) => {
        if (res.success) {
          this.teamsList = res.result;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.invoiceNumberInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.happyNewsDetail {
  // padding: 15px;
  .el-form-item {
    margin-bottom: 0;
    line-height: 28px;
  }
  .el-form-item__label,
  .el-form-item__content {
    line-height: 28px;
    font-size: 13px;
    color: #606266;
  }
  .el-form-item__content {
    color: #333;
  }
  .contral {
    right: 30px;
    position: absolute;
    color: #999;
    i:hover {
      color: #e60012;
    }
    .icon-kh_gsxq_bj {
      font-size: 16px;
      font-weight: normal;
      margin-right: 15px;
    }
    .el-icon-delete {
      font-size: 18px;
    }
  }
  .titleRecord {
    // overflow:hidden;
    line-height: normal;
    margin-bottom: 15px;
    vertical-align: middle;
    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .el-collapse-item__wrap,
  .el-collapse,
  .el-collapse-item__header {
    border: none;
  }
  .el-collapse-item__arrow {
    color: #bbbbbb;
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  .el-collapse-item__header {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    background: #f5f5f5;
    position: relative;
  }
  .el-collapse-item {
    border: 1px solid #eee;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-collapse-item__content {
    border-top: solid 1px #ededed;
    padding: 10px;
    font-size: 13px;
    color: #666666;
    .el-row {
      line-height: 26px;
    }
  }
  .contractInvoices {
    border: solid 1px #ededed;
    border-left: 2px #777788 solid;
    padding: 20px 20px 5px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 13px;
    .el-col-12 {
      margin-bottom: 15px;
    }
  }
  .approvalTime {
    color: #999;
  }
  .el-timeline-item {
    padding-bottom: 1px;
    div {
      margin-bottom: 4px;
    }
    .el-timeline-item__wrapper {
      margin-bottom: 15px;
    }
  }
}
.msgClass {
  width: 600px;
}
</style>
<style lang="scss" scoped>
.canRecordBox {
  border: 1px solid #eee;
  position: relative;
  line-height: normal;
  .canRecord {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    background: #f5f5f5;
  }
  .canRecordcon {
    font-size: 13px;
    padding: 0 20px;
    max-height: 60vh;
    overflow: auto;
  }
}

.el-row {
  line-height: 40px;
}

.operateBtn {
  text-align: center;
  border-top: 1px solid #ededed;
  line-height: 50px;
  position: fixed;
  bottom: 20px;
  width: calc(100% - 40px);
  background-color: #f9f9f9;
  .el-button + .el-button {
    margin-left: 8px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px 15px;
  line-height: 40px;
}
</style>
<style  lang='less'>
.happyNewsDetail {
  //   .heards {
  // background-color: #f5f7fa;
  // padding: 15px;
  .tips {
    height: 35px;
    line-height: 35px;
    font-size: 13px;
    color: #606266;
    padding: 0 10px;
    margin: 15px;
    background-color: #fff9e6;
    border: 1px solid #ffdf94;
    i {
      color: #ff9900;
      font-size: 16px;
      margin-right: 10px;
      vertical-align: baseline;
    }
  }
  .scoreTitle {
    margin: 0;
    border: 1px solid #e5e5e5 !important;
    .el-collapse-item__content {
      padding-bottom: 10px;
    }
    .scoreTotal {
      font-size: 14px;
      padding: 0 10px;
      color: #333;
      width: 100%;
      line-height: 35px;
      background-color: #f0f0f0;
      font-weight: bold;
      span {
        font-weight: normal;
        color: #606266;
        s {
          text-decoration: none;
          color: #e58a00;
        }
      }
      .addSearchBtn {
        float: right;
        color: #526ecc;
        font-size: 12px;
        i {
          font-size: 14px;
        }
      }
    }
  }
  .el-collapse-item__header {
    height: 35px;
    line-height: 35px;
    border: none;
    background-color: #f0f0f0;
  }
  //   }

  .searchList {
    font-size: 0;
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .liStyle {
      min-height: 40px;
      width: 100%;
      margin: 0;
      padding: 0 15px 0 10px;
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
    .el-form-item__label,
    .el-form-item__content {
      font-size: 12px;
      line-height: normal;
      line-height: 32px;
    }
    .el-icon-delete {
      color: #999;
      font-size: 13px;
      &:hover {
        color: #e60012;
      }
    }
  }
  .icon-wenhao-xiangsu {
    font-weight: normal;
    font-size: 16px;
    color: #898989;
    margin-left: 10px;
  }
}
.titleOfPerformance {
  position: relative;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  .el-collapse-item__header::after {
    content: none !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__content {
    padding: 0 !important;
  }
}
.contentOfPerformance {
  background-color: #fff;
  padding: 15px 10px;
  .el-form-item {
    padding: 5px 0;
    margin: 0;
    .el-form-item__label {
      font-size: 14px;
    }
  }
  .canEdit {
    padding: 0 10px;
    i {
      display: none;
    }
    &:hover {
      background-color: #f9f9f9;
      i {
        display: inline-block;
      }
    }
  }
}
.editPercent {
  .el-dialog__body {
    padding: 10px 40px 20px;
    .el-form-item__error {
      padding-top: 0 !important;
    }
  }
}
.iconOfClose {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #606266;
  &:hover {
    color: #e60012;
  }
}
</style>
